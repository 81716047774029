import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { StatusUser } from '@core/enum/user';
import { ResponseApi, ResponseListApi } from '@core/models/interfaces/common';
import { GetUserParams, UserCreate, UserDetail } from '@core/models/interfaces/user';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private baseUrl = `${environment.API_URL}/crm/users`;

  constructor(private httpClient: HttpClient) {}

  getUsers(params?: GetUserParams) {
    return this.httpClient.get<ResponseListApi<UserDetail>>(this.baseUrl, { params: { ...params } });
  }

  createUser(body: UserCreate) {
    return this.httpClient.post<ResponseApi<UserDetail>>(this.baseUrl, body);
  }

  deleteUser(id: string) {
    return this.httpClient.delete(`${this.baseUrl}/${id}`);
  }

  updateUser(id: string, body: UserCreate) {
    return this.httpClient.put(`${this.baseUrl}/${id}`, body);
  }

  getUserById(id: string) {
    return this.httpClient.get<ResponseApi<UserDetail>>(`${this.baseUrl}/${id}`);
  }

  changeStatusUserById(id: string, status: StatusUser) {
    let params = new HttpParams();
    if (status) {
      params = params.set('status', status);
    }
    return this.httpClient.put(`${this.baseUrl}/${id}/status`, null, { params });
  }

  exportUsers(params?: GetUserParams) {
    let queryParams = new HttpParams();

    if (params?.keyword !== undefined) {
      queryParams = queryParams.append('keyword', params.keyword);
    }

    if (params?.departmentIds !== undefined) {
      for (let id of params?.departmentIds) {
        queryParams = queryParams.append('departmentIds', id);
      }
    }

    if (params?.statusIds !== undefined) {
      for (let id of params?.statusIds) {
        queryParams = queryParams.append('statusIds', id);
      }
    }

    if (params?.groupId !== undefined) {
      queryParams = queryParams.append('groupId', params.groupId);
    }

    return this.httpClient.get(`${this.baseUrl}/export/users`, {
      params: queryParams,
      responseType: 'blob'
    });
  }
}
